<template>
  <v-container>

    <v-form ref="form" v-model="valid">
      <v-card
        class="pa-md-4 mx-lg-auto px-3 py-2"
        outlined
      >
        <v-row>
          <v-col>
            <v-btn
              color="warning"
              class="mr-4"
              @click="goBack()"
              small
            >
              <v-icon>mdi-keyboard-backspace</v-icon>&nbsp;Kembali
            </v-btn>

            <v-btn
              color="error"
              class="mr-4"
              @click="resetForm"
              v-if="!isEdit"
              small
            >
              <v-icon>mdi-restart</v-icon>&nbsp;Reset Form
            </v-btn>
      
          </v-col>
          <v-col class="text-right">

            <v-btn :disabled="!valid" 
              color="primary"
              class="mr-4"
              @click="saveEditForm()"
            >
              <v-icon>mdi-content-save</v-icon>&nbsp;Simpan Surat
            </v-btn>
      
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
          <div class="subtitle-2 text-center grey lighten-2 px-2 py-2">SURAT TUGAS</div>
           </v-col>
          <v-col>
              <table width="100%">

                <tr>
                  <td width="100px;">Penandatangan</td>
                  <td width="10px;">:</td>
                  <td width="80%" class="subtitle-2"><span v-if="vPenandatangan">{{ vPenandatangan ? vPenandatangan.pos_name : '-' }}</span></td>
                </tr>
                 <tr>
                  <td >Sifat</td>
                  <td>:</td>
                  <td class="subtitle-2"><span v-if="vSifat">{{ vSifat.sifat ? vSifat.sifat : '-' }}</span></td>
                </tr>
                 <tr>
                  <td >Hal</td>
                  <td>:</td>
                  <td class="subtitle-2"><span v-if="dataSurat">{{ dataSurat.perihalSurat ? dataSurat.perihalSurat : '-' }}</span></td>
                </tr>
                <tr>
                  <td >Tgl. Surat</td>
                  <td>:</td>
                  <td class="subtitle-2"><span v-if="dataSurat">{{ dataSurat.tglSurat ? dataSurat.tglSurat : '-' | formatDate}}</span></td>
                </tr>
              </table>
              
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
          <v-select
                      style="z-index:200;"
                      :items="listKedinasan"
                      name="value"
                      label="Pilih Tipe Kedinasan"
                      v-model="selectKedinasan"
                      item-text="text"
                      @change="changeKedinasan()"
                      outlined
                      ></v-select>
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <div id="defaultRTE">
              <span class="subtitle-2">PARAGRAF AWAL:</span>
              <froala height="250" id="editpar1" :tag="'textarea'" :config="configFroala" v-model="par1"></froala>
            </div>
          </v-col>

          <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="postForm.st_tempat"
                  counter
                  :rules="[rules.required]"
                  label="Tempat Pelaksanaan"
                  outlined
                ></v-text-field>  
              </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                md="1"
              >
              &nbsp;
            </v-col>
           <v-col
              cols="12"
              md="4"
            >
              <v-dialog
                  ref="SelRefTglStart"
                  v-model="selTglStart"
                  :return-value.sync="dataSurat.st_dateStart"
                  persistent
                  width="290px"
                >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dataSurat.st_dateStart"
                    label="Tgl Mulai"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-on="on"
                    :rules="[rules.required]"
                    outlined
                  ></v-text-field>
                </template>
                  <v-date-picker 
                  v-model="dataSurat.st_dateStart"
                  >
                  <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="selTglStart = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.SelRefTglStart.save(dataSurat.st_dateStart)">OK</v-btn>
                  </v-date-picker>
                  
              </v-dialog>
              
            </v-col>
            <v-col
                cols="12"
                md="2"
              >
              <div class="d-flex justify-center align-center">S/D</div>
            </v-col>

          <v-col
              cols="12"
              md="4"
            >
              <v-dialog
                  ref="SelRefTglEnd"
                  v-model="selTglEnd"
                  :return-value.sync="dataSurat.st_dateEnd"
                  persistent
                  width="290px"
                >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dataSurat.st_dateEnd"
                    label="Tgl Selesai"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-on="on"
                    :rules="[rules.required]"
                    outlined
                  ></v-text-field>
                </template>
                  <v-date-picker 
                  v-model="dataSurat.st_dateEnd"
                  >
                  <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="selTglEnd = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.SelRefTglEnd.save(dataSurat.st_dateEnd)">OK</v-btn>
                  </v-date-picker>
                  
              </v-dialog>
              
            </v-col>
            <v-col
                cols="12"
                md="1"
              >
              &nbsp;
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                md="2"
              >
              &nbsp;
            </v-col>

            <v-col
                cols="12"
                md="3"
              >
              <v-menu
                ref="selST_timeStart"
                v-model="seltimeStart"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time1"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="postForm.st_timeStart"
                    label="Waktu Mulai"
                    readonly
                    v-on="on"
                    outlined
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="seltimeStart"
                  v-model="postForm.st_timeStart"
                  full-width
                  @click:minute="$refs.selST_timeStart.save(time1)"
                  format="24hr"
                  scrollable
                  min="8:00"
                  max="22:00"
                  :allowed-minutes="allowedStep"
                ></v-time-picker>
              </v-menu>
            </v-col>

            <v-col
                cols="12"
                md="3"
              >
              <v-menu
                ref="selST_timeEnd"
                v-model="seltimeEnd"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time2"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="postForm.st_timeEnd"
                    label="Waktu Selesai"
                    readonly
                    v-on="on"
                    :disabled="postForm.st_isTimeEndTick == 1"
                    outlined
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="seltimeEnd"
                  v-model="postForm.st_timeEnd"
                  full-width
                  @click:minute="$refs.selST_timeEnd.save(time2)"
                  format="24hr"
                  scrollable
                  min="8:00"
                  max="22:00"
                  :allowed-minutes="allowedStep"
                ></v-time-picker>
              </v-menu>
            </v-col>

            <v-col
            cols="12"
            md="2"
          >
            <v-switch
              v-model="postForm.st_isTimeEndTick"
              label="Selesai"
              outlined
            ></v-switch>
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
          &nbsp;
        </v-col>

            <v-col
                cols="12"
                md="12"
              >
              <v-text-field
                v-model="postForm.st_uraian_tugas"
                :rules="[rules.required]"
                counter
                label="Uraian Tugas"
                outlined
              ></v-text-field>  
            </v-col>

           <v-col
            cols="12"
            md="12"
          >
            <div id="defaultRTE">
              <span class="subtitle-2">PARAGRAF AKHIR:</span>
              <froala height="250" id="editpar2" :tag="'textarea'" :config="configFroala" v-model="par2"></froala>
            </div>
          </v-col>

        </v-row>
        <v-row>
           <v-col
            cols="12"
            md="12"
          >

            <v-combobox
              v-model="tembusanSurat"
              :items="dataListUserTo"
              label="Tembusan"
              multiple
              chips
              @change="cekStatusTembusan()"
              item-value="value"
              item-text="text"
            ></v-combobox>

          </v-col>
        </v-row>
      
        <hr>
        <v-spacer></v-spacer>
        
        <v-row v-if="dataSurat.scanSurat">
           <v-col
            cols="12"
            md="12"
          >
          <div class="subtitle-2 text-center grey lighten-2 px-2 py-2">DAFTAR PELAKSANA TUGAS</div>
           </v-col>

          <v-col cols="12">
            <v-btn small color="info" @click="addDaftarNama()">
              <v-icon>mdi-plus</v-icon>Tambah Daftar Pelaksana Tugas
            </v-btn>
          </v-col>
          <v-col
            cols="12"
          >
          <v-data-table
              :headers="headerDaftarPelaksana"
              :items="dataListDaftarNama"
              class="elevation-1"
              :loading="listLoading"
            >

            <template v-slot:item.name="{ item }">
              <div v-if="item.hruserpositions_pegawai > 0" class="font-weight-medium">{{ item.pegawaiName }}</div>
              <div v-else class="font-weight-medium">{{ item.daftarNama }}</div>
            </template>

            
             <template v-slot:item.status="{ item }">
               <div class="d-flex justify-center">
                 <span v-if="item.badgeStatus.id == 4" style="padding:2px;background-color:green;color:white;border-radius: 10px;">{{item.badgeStatus.text}}</span>
                 <span v-else-if="item.badgeStatus.id == 11" style="padding:2px;background-color:grey;color:white;border-radius: 10px;">{{item.badgeStatus.text}}</span>
                 <span v-else style="padding:2px;background-color:grey;color:white;border-radius: 10px;">{{item.badgeStatus.text}}</span>
                
              </div>
            </template>

            <template v-slot:item.aksi="{ item }">
              
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn color="green" v-on="on" text x-small @click="viewDaftarNama(item)" >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>View</span>
              </v-tooltip>
 
              &nbsp;

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  
                  <v-btn color="warning" v-if="action.imKonseptor && dataSurat.statusSurat != 21 && item.eostatus != 22" v-on="on" text x-small @click="editDaftarNama(item)" >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Ubah</span>
              </v-tooltip>
 
              &nbsp;

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn color="red" v-if="action.imKonseptor && dataSurat.statusSurat != 21 && item.eostatus != 22" v-on="on" text x-small @click="delDaftarNama(item.id)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Hapus Data</span>
              </v-tooltip>
              
            </template>

          </v-data-table>
          </v-col>
        </v-row>

        <v-alert v-if="dataSurat.scanSurat"
      dense
      outlined
      type="error"
    >
      Setiap melakukan perubahan / update pada <b>DAFTAR PELAKSANA TUGAS</b> harap untuk melakukan Klik pada tombol Simpan Surat agar sistem men-Generate ulang Softcopy Surat (PDF).
    </v-alert>
          <hr>
          <v-spacer></v-spacer>
           <v-row>
          <v-col>
            <v-btn
              color="warning"
              class="mr-4"
              @click="goBack()"
              small
            >
              <v-icon>mdi-keyboard-backspace</v-icon>&nbsp;Kembali
            </v-btn>

            <v-btn
              color="error"
              class="mr-4"
              @click="resetForm"
              v-if="!isEdit"
              small
            >
              <v-icon>mdi-restart</v-icon>&nbsp;Reset Form
            </v-btn>
      
          </v-col>
          <v-col class="text-right">

            <v-btn :disabled="!valid" 
              color="primary"
              class="mr-4"
              @click="saveEditForm()"
            >
              <v-icon>mdi-content-save</v-icon>&nbsp;Simpan Surat
            </v-btn>
      
          </v-col>
        </v-row>

      </v-card>
    </v-form>
    <v-snackbar
        v-model="toast.show"
        :timeout="toast.timeout"
      >
        {{ toast.text }}
        <v-btn
          :color="toast.color"
          text
          @click="toast.show = false"
        >
          Close
        </v-btn>
      </v-snackbar>

      <v-dialog v-model="isLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line centered>
                    <v-list-item-content>
                      <div class="overline mb-4">Processing</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

      <v-dialog v-model="dialogConfirm" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Konfirmasi</v-card-title>
          <v-card-text>Apakah anda yakin data tersebut sudah benar?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="dialogConfirm = false">Batal</v-btn>
            <v-btn  color="blue darken-1" @click="updateData()">Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogDelDaftarConfirm" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Konfirmasi</v-card-title>
          <v-card-text>Apakah anda yakin ingin menghapus data tersebut?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="dialogDelDaftarConfirm = false">Batal</v-btn>
            <v-btn  color="blue darken-1" @click="dodelDaftarNama()">Hapus</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogPegawai" persistent width="70vW">
        <v-card>
            <form-addpegawai v-if="dialogPegawai" :data-surat="dataSurat" :is-edit="isformEditPegawai" :seldaftarnama="sel_daftarNama" @dialogPegawaiClose="closeDialogaddPegawai()" />
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogviewPegawai" persistent width="70vW">
        <v-card>
          <form-viewpegawai v-if="dialogviewPegawai" :seldaftarnama="sel_daftarNama" @dialogviewPegawaiClose="closeDialogviewPegawai()" />
      </v-card>
    </v-dialog>


</v-container>
</template>

<script>
import secureStorage from '@/utils/secureStorage'
import { splitComponentsByComma } from '@/utils/index'

import { insertDataSurat, getDataSuratByLetterGUID, updateDataSurat, printSuratbyGUID } from '@/api/datasurat'
import { getDaftarPegawaiSTALL, getDaftarNamaByID, insertDaftarPegawaiST, deleteDaftarPegawaiST, updateDaftarPegawaiST } from '@/api/daftarpegawaiST'
import { _GLOBAL } from '@/api/server'
import moment from 'moment'
import formAddpegawai from './formAddPegawai'
import formViewpegawai from './formViewPegawai'

const defaultForm = {
  isiSuratAtas: '',
  isiSuratBawah: '',
  lampiranSurat:'',
  tembusanSurat:'',
  tipeKedinasan: 'dalamkota',
  st_tempat: '',
  st_uraian_tugas: '',
  st_dateStart: '',
  st_dateEnd: '',
  st_timeStart: '',
  st_timeEnd: '',
  st_isTimeEndTick: 0,
}

export default {
  name: 'FormEditor5',
  components: {
    formAddpegawai, formViewpegawai
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      postForm: Object.assign({}, defaultForm),
      dataSurat: {},
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      valid: false,
      rules: {
        required: v => !!v || 'Required.',
        maxlength: v => v.length <= 300 || 'Max 300 characters',
      },
      listKedinasan: [
        { text: 'Dalam Kota', value: 'dalamkota'},
        { text: 'Luar Kota', value: 'luarkota'},
        { text: 'Luar Negeri', value: 'luarnegeri'}
      ],
      action: { imKonseptor: false, canEdit: false, canReqApproval: false, canAddDaftarNama: false },
      selTglStart: false,
      selTglEnd: false,
      seltimeStart: false,
      seltimeEnd: false,
      time1: null,
      time2: null,
      allowedStep: m => m % 15 === 0,
      selectKedinasan: 'dalamkota',
      isLoading: false,
      listLoading: false,
      dialogConfirm: false,
      dialogPegawai: false,
      dialogviewPegawai:false,
      dialogeditPegawai:false,
      dialogDelDaftarConfirm: false,
      isformEditPegawai: false,
      dataUser: [],
      currrole: [],
      lGUID: '',
      idLetter: '',
      configFroala: {
        key: "eHE5C-11G2D2C1D2A5D5D-17jynH-9F4bC-22A8yunD5D4B3E3C3A6A5C2C4G5==",
        tabSpaces: 4,
        heightMin:400,
        toolbarButtons: {
          'moreText': {
            'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
          },
          'moreParagraph': {
            'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
          },
          'moreRich': {
            'buttons': ['insertLink', 'insertImage', 'insertVideo', 'insertTable', /*'emoticons', 'fontAwesome',*/ 'specialCharacters', /*'embedly', 'insertFile',*/ 'insertHR']
          },
          'moreMisc': {
            'buttons': ['undo', 'redo', 'fullscreen', /*'print', 'getPDF', 'spellChecker',*/ 'selectAll', 'html', 'help'],
            'align': 'right',
            'buttonsVisible': 2
          }
        },
        events: {
          initialized: function () {
            console.log('initialized')
          },
          "image.beforeUpload": function(files) {
            console.log('image.beforeUpload')
            var editor = this;
            if (files.length) {
              // Create a File Reader.
              var reader = new FileReader();
              // Set the reader to insert images when they are loaded.
              reader.onload = function(e) {
                var result = e.target.result;
                editor.image.insert(result, null, null, editor.image.get());
              };
              // Read image as base64.
              reader.readAsDataURL(files[0]);
            }
            editor.popups.hideAll();
            // Stop default upload chain.
            return false;
          }
        }
      },
      vPenandatangan: null,
      vSifat: null,
      tujuanSurat: null,
      tembusanSurat: null,
      dataListUserTo: [],
      st_isLuarNegeri: false,
      par1: '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sehubungan dengan akan diadakannya pertemuan terkait ...., maka dengan ini kami menugasi nama-nama terlampir untuk dapat melaksanakan kegiatan/perjalanan dinas sebagai berikut:',
      par2: '<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Segala biaya yang timbul sebagai akibat dilaksanakannya surat tugas ini di bebankan pada DIPA BPDPKS TA ' + moment().format('YYYY')+ '.</p><p>Surat Tugas ini disusun untuk dilaksanakan dan setelah selesai dilaksanakan, pelaksana segera menyampaikan laporan. Kepada Instansi terkait, kami mohon bantuan demi kelancaran pelaksanaan tugas tersebut.</p><br><p>Demikian Surat Tugas ini dibuat untuk dilaksanakan dengan penuh tanggung jawab.</p>',
      parLN: '<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dengan rincian waktu sebagai berikut:</p>'
            + '<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; a.Waktu perjalanan pergi  : .... hari, tanggal hari bulan tahun</p>'
            + '<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; b.Waktu pelaksanaan kegiatan  : .... hari, tanggal hari bulan tahun s.d. hari bulan tahun</p>'
            + '<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; c.Waktu perjalanan pulang  : .... hari, tanggal hari bulan tahun.</p>'
            + '<br><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Segala biaya yang timbul sebagai akibat dilaksanakannya surat tugas ini di bebankan pada DIPA BPDPKS TA ' + moment().format('YYYY')+ ' dengan target kinerja atau hasil yang akan dicapai adalah</p>'
            + '<br>.................................'
            + '<br><p>Surat Tugas ini disusun untuk dilaksanakan dan setelah selesai dilaksanakan, pelaksana segera menyampaikan laporan. Kepada Instansi terkait, kami mohon bantuan demi kelancaran pelaksanaan tugas tersebut.</p><br><p>Demikian Surat Tugas ini dibuat untuk dilaksanakan dengan penuh tanggung jawab.</p>',
      parBCup: '',
      headerDaftarPelaksana: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
          width:'70',
          class:'subtitle-1'
        },
        { text: 'Nama', value: 'name',  width:'150', align: 'left', class:'subtitle-1'},
        { text: 'Unit / Instansi', value: 'unit_instansi', align: 'center', class:'subtitle-1'},
        { text: 'Tanggal', value: 'tanggal', width:'200', align: 'center', sortable: true , class:'subtitle-1'},
        { text: 'Status', value: 'status', align: 'center', class:'subtitle-1'},
        { text: 'Aksi', width:'100', value: 'aksi', align: 'center', class:'subtitle-1'}
      ],
      dataListDaftarNama: [],
      sel_daftarNama: {},
      id_daftarNama: 0
    }
  },
  filters: {
    formatDate: function (value) {
      if (!value) return ''
      var sdate = moment(value).lang("id").format('DD MMMM YYYY')
      return sdate
    }
  },
  provide:{
    richtexteditor:[Toolbar, Image, Link, HtmlEditor, QuickToolbar]
  },
  created () {
    const vm = this

    vm.lGUID = vm.$route.params && vm.$route.params.lguid
    vm.currrole = secureStorage.getItem('currRole')
    // console.log(vm.currrole)

    vm.dataUser = secureStorage.getItem('user')
    if (vm.dataUser !== null) {
      // console.log(vm.dataUser)
      if(vm.dataUser.role !== null) {
        if(vm.dataUser.role.id === 1) {
          vm.isAdmin = true
        }
      }
    }

    vm.parBCup = vm.par2
    vm.selectKedinasan = 'dalamkota'

    vm.getUsersToData()
  },
  destroyed () {
  },
  mounted () {
    const vm = this
    vm.fetchData(vm.lGUID)

  },
  computed: {

  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    closeDialogaddPegawai(){
      this.dialogPegawai = false
      this.getListDaftarNama(this.idLetter)
    },
    closeDialogviewPegawai(){
      this.dialogviewPegawai = false
    },
    resetForm () {
      this.$refs.form.reset()
    },
    changeKedinasan(){
      // console.log('changeKedinasan',this.selectKedinasan)
      if(this.selectKedinasan == 'luarnegeri'){
        if(this.dataSurat.isiSuratBawah == null){
          this.parBCup = this.par2
          this.par2 = this.parLN
        }
      }else{
        if(this.dataSurat.isiSuratBawah == null){
          this.par2 = this.parBCup
        }
      }
      
    },
    getUsersToData() {
      const vm = this
      var rData = secureStorage.getItem('userposall')
      // console.log(rData)
      if (rData !== null) {
        let temp = []
        rData.forEach(element => {
          if (element != null) {
            if (element.rolename != 'admin' && element.rolename != 'developer' && element.rolename != 'admin_sdm' && element.rolename != 'sekretaris' && element.rolename != 'staff') {
              // console.log(element)
              if (element.prefix != null) { element.prefix = element.prefix + ' ' } else { element.prefix = '' }

              let uposid = element.id
              element['posname'] = element.prefix + element.pos_name
              element['userposname'] = element.fullname + ' ('  + element['posname'] + ')'
              element['unitcode'] = parseInt(element.unit_code)

              let tmp = {text:  element['userposname'], value: uposid, unitcode: element['unitcode']}
              temp.push(tmp)
            }
          }
        })
        
        temp.sort((a, b) => Number(a.unitcode) - Number(b.unitcode))
        vm.dataListUserTo = temp
        // console.log(vm.dataListUserTo)
      }
      
    },
    initStatusTembusan(){
      const vm = this
      var datauserposall = vm.dataListUserTo
      let tos = vm.dataSurat.tembusanSurat
      tos = tos.toString()
      // console.log(tos)
      if(tos){
        vm.tembusanSurat = []
        // let arrtos =splitComponentsByComma(tos)
        let arrtos = tos.split('|')
    
        if(arrtos.length > 0 && datauserposall.length > 0){
          // console.log(arrtos)
          arrtos.forEach(idtos => {
            let isIntid =  parseInt(idtos)
            if(isIntid > 0){
              let data = datauserposall.find(x => x.value === isIntid)
              if(data){
                vm.tembusanSurat.push(data)
              }
            }else{
              if(idtos != ''){
                vm.tembusanSurat.push(idtos)
              }
            }
          })
        }
      }
    // console.log(vm.tembusanSurat)
    },
    cekStatusTembusan(){
      const vm = this
      var array = vm.tembusanSurat
       // console.log('cekStatusTembusan',array)
      let tmp = []
      if(array){
        array.forEach(element => {
          if(element){
            if(element.value){
              tmp.push(element.value)
            }else if(element != ''){
              tmp.push(element)
            }
          }
        });
      }
      
      vm.dataSurat.tembusanSurat = tmp
      // console.log(vm.dataSurat.tembusanSurat)
    },
    fetchData (lguid) {
      const vm = this
      vm.isLoading = true
      getDataSuratByLetterGUID(lguid).then(response => {
        let data = response.data
        
        if(data.length > 0){
          vm.dataSurat = data[0]
          // console.log(vm.dataSurat)

          vm.idLetter = vm.dataSurat.id
          vm.getListDaftarNama()

          if(vm.dataSurat.hruserpositions_penandatangan){
            var datauserposall = secureStorage.getItem('userposall')
            vm.vPenandatangan = datauserposall.find(x => x.id === vm.dataSurat.hruserpositions_penandatangan.id)
          }

          if(vm.dataSurat.eosifatsurat){
            vm.vSifat = vm.dataSurat.eosifatsurat
          }

          if(vm.dataSurat.isiSuratAtas){
            vm.par1 = vm.dataSurat.isiSuratAtas
          }
          if(vm.dataSurat.isiSuratBawah){
            vm.par2 = vm.dataSurat.isiSuratBawah
          }

          if(vm.dataSurat.st_dateStart){
            vm.dataSurat.st_dateStart = moment(vm.dataSurat.st_dateStart).format('YYYY-MM-DD')
          }
          if(vm.dataSurat.st_dateEnd){
            vm.dataSurat.st_dateEnd = moment(vm.dataSurat.st_dateEnd).format('YYYY-MM-DD')
          }

          if(vm.dataSurat.st_timeStart){
            var d = new moment(vm.dataSurat.st_timeStart, 'HH:mm:ss.SSS')
            vm.postForm.st_timeStart = moment(d).format('HH:mm')
          }

          if(vm.dataSurat.st_timeEnd){
            var d = new moment(vm.dataSurat.st_timeEnd, 'HH:mm:ss.SSS')
            vm.postForm.st_timeEnd = moment(d).format('HH:mm')
          }

          if(vm.dataSurat.st_isTimeEndTick == 1){
            vm.postForm.st_timeEnd = ''
            vm.postForm.st_isTimeEndTick = 1
          }

          if(vm.dataSurat.st_tempat){
            vm.postForm.st_tempat = vm.dataSurat.st_tempat
          }

          if(vm.dataSurat.st_uraian_tugas){
            vm.postForm.st_uraian_tugas = vm.dataSurat.st_uraian_tugas
          }

          if(vm.dataSurat.statusSurat){
            vm.dataSurat.statusSurat = vm.dataSurat.statusSurat.id
          }
          
          if(vm.dataSurat.hruserpositions_createdBy){
            if(vm.dataSurat.hruserpositions_createdBy.id == vm.currrole.id){
              vm.action.imKonseptor = true
            }
          }

          // console.log(vm.postForm)
          
          vm.initStatusTembusan()

          delete vm.dataSurat.created_at
          delete vm.dataSurat.updated_at
        }
        vm.isLoading = false
      }).catch(err => {
        // console.log(err)
        vm.isLoading = false
      })
    },
    
    saveEditForm () {
      const vm = this

      if(vm.$refs.form.validate()){
        vm.cekStatusTembusan()
        vm.postForm['isiSuratAtas'] = vm.par1
        vm.postForm['isiSuratBawah'] = vm.par2
        vm.postForm['tipeKedinasan'] = vm.selectKedinasan
        
        let tos = vm.dataSurat.tembusanSurat
        if(tos){
          var strtos = tos.join("|");
          vm.postForm['tembusanSurat']  = strtos.toString()
        }

        if (moment(vm.dataSurat.st_dateStart).isValid()) {
          vm.postForm.st_dateStart = moment(vm.dataSurat.st_dateStart).format('YYYY-MM-DD')
          // console.log(vm.postForm.st_dateStart)
        }

        if (moment(vm.dataSurat.st_dateEnd).isValid()) {
          vm.postForm.st_dateEnd = moment(vm.dataSurat.st_dateEnd).format('YYYY-MM-DD')
          // console.log(vm.postForm.st_dateEnd)
        }

        var st_timeStart = vm.postForm.st_timeStart ? vm.postForm.st_timeStart : '00:00:00.000'
        var st_timeEnd = vm.postForm.st_timeEnd ? vm.postForm.st_timeEnd : '00:00:00.000'
        st_timeStart = st_timeStart.replace(':00.000','')
        st_timeEnd = st_timeEnd.replace(':00.000','')
        vm.postForm.st_timeStart = st_timeStart + ':00.000'
        vm.postForm.st_timeEnd =  st_timeEnd + ':00.000'

        vm.postForm.hruserpositions_editedBy = vm.currrole.id
        vm.postForm.userEditor = vm.dataUser.id

        if(vm.postForm.st_isTimeEndTick == 1){
          vm.postForm.st_timeEnd = '00:00:00.000'
        }
      
        // console.log(vm.postForm)
        vm.dialogConfirm = true
      }
      else{
        vm.toast = {
          show: true, color:'red', text: 'Form still has an error!', timeout: 2000
        }
      }
    },
    updateData () {
      const vm = this
      vm.dialogConfirm = false
      var modelsurat = 5 // ST

      // console.log('vm.idLetter: ', vm.idLetter)
      vm.isLoading = true
      // console.log('update:',vm.postForm)
      updateDataSurat(vm.postForm, vm.idLetter).then(response => {
        // console.log('updateDataSurat', response.data)
        var data = response.data
        vm.isLoading = true
        printSuratbyGUID(modelsurat, vm.lGUID).then(response => {
          // console.log('printSuratbyGUID Data', response.data)
          vm.isLoading = false
          vm.toast = {
            show: true, color:'green', text: 'Generate PDF BERHASIL.', timeout: 2000
          }
          vm.goBack()
        })
        .catch(err => {
          // console.log(err)
          vm.isLoading = false
          vm.toast = {
            show: true, color:'red', text: 'Generate PDF GAGAL, silakan dicoba kembali.', timeout: 2000
          }
        })
      })
      .catch(err => {
        console.log(err)
        vm.isLoading = false
        vm.toast = {
          show: true, color:'red', text: 'Update Data GAGAL, silakan dicoba kembali.', timeout: 2000
        }
      })
    },
    getListDaftarNama() {
      const vm = this
      this.isLoading = true
      // console.log('getListDaftarNama')

      getDaftarPegawaiSTALL(vm.idLetter).then(response => {
        vm.isLoading = false
        var rData = response.data
        // console.log(rData)
        if (rData !== null) {
          rData.forEach(element => {
            if (element.hruserpositions_pegawai > 0) {
              element['toName'] = element.pegawaiName
              element['unit_instansi'] = element.unit_instansi
            } else {
              element['toName'] = element.daftarNama
              element['unit_instansi'] = element.unit_instansi
            }

            var dateStart = element['dateStart']
            if (dateStart != '0000-00-00') {
              dateStart = moment(dateStart).format('DD MMM YYYY')
            }
            var dateEnd = element['dateEnd']
            if (dateEnd != '0000-00-00') {
              dateEnd = moment(dateEnd).format('DD MMM YYYY')
            }
            if (dateStart != dateEnd) {
              element['tanggal'] = dateStart + ' - ' + dateEnd
            } else {
              element['tanggal'] = dateStart
            }

            element['inMyDir'] = false
            if (element['pegawaiUnitCode'] != null) {
              var myUnitCode = vm.currrole.hrorganization.unit_code
              var one = myUnitCode.toString().slice(0, 2)
              var two = element['pegawaiUnitCode'].toString().slice(0, 2)
              if (one === two) {
                element['inMyDir'] = true
              }
            }

            element['badgeStatus'] = { text:'', color: 'white', id: 0}
            if(element.eostatus == 11){
              element['badgeStatus'] = { text: element.approvalStatus, color: 'grey', id: element.eostatus}
            }else{
              element['badgeStatus'] = { text: element.approvalStatus, color: 'green', id: element.eostatus}
            }

          })
          vm.dataListDaftarNama = rData
          // console.log(vm.dataListDaftarNama)
        }
      }).catch(err => {
        // console.log(err)
        vm.isLoading = false
        vm.toast = {
          show: true, color:'red', text: 'Fetch Data GAGAL, silakan refresh browser!', timeout: 2000
        }
      })
    },
    addDaftarNama(){
      this.dialogPegawai = true
      this.isformEditPegawai = false
    },
    viewDaftarNama(objDaftarNama){
      // console.log('viewDaftarNama',objDaftarNama)

      this.sel_daftarNama = objDaftarNama
      this.dialogviewPegawai = true
    },
    editDaftarNama(objDaftarNama) {
      // console.log('editDaftarNama',objDaftarNama)
      this.sel_daftarNama = objDaftarNama

      this.dialogPegawai = true
      this.isformEditPegawai = true
    },
    delDaftarNama(id){
      this.dialogDelDaftarConfirm = true
      this.id_daftarNama = id
    },
    dodelDaftarNama(){
      const vm = this
      vm.dialogDelDaftarConfirm = false

      // console.log('delDaftarNama',vm.id_daftarNama)
      deleteDaftarPegawaiST(vm.id_daftarNama).then(response => {
        // console.log(response)
        vm.isLoading = false
        vm.getListDaftarNama()
      }).catch(err => {
        // console.log(err)
        vm.isLoading = false
        vm.toast = {
          show: true, color:'red', text: 'Hapus Daftar Pelaksana Tugas  GAGAL, silakan dicoba kembali!', timeout: 2000
        }
      })
    }
  }
}

</script>
<style >

.v-menu__content{
  z-index: 101 !important;
}


</style>